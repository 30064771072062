import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FieldArray, fieldArrayFieldsPropTypes, ReduxFormContext } from 'redux-form';
import * as yup from 'yup';
import { loadDrivingLicenseClasses, loadDrivingLicenseValidityCodes } from '../../../../actions';
import DateField from '../../../../components/shared/form-v2/DateField';
import SelectField from '../../../../components/shared/form-v2/SelectField';
import useCustomerSource from '../../../../components/utilities/useCustomerSource';
import { useCountry } from '../../../../hookSelectors';
import { CustomerDetailsSource, LicenseType } from '../../../../schema';
import { getDrivingLicenseClasses, getDrivingLicenseValidityCodes } from '../../../../selectors';
import { getLicenseTypeOptions } from '../../../../utilities/constants/options';

const LicenseClass = ({ disabled = false, licenseType, drivingLicenseClasses, field, appliedForInsurance }) => {
    const { t } = useTranslation();
    const isQualified = licenseType === LicenseType.QUALIFIED;
    const isProvisional = licenseType === LicenseType.PROVISIONAL;

    return (
        <Fragment key={field}>
            {(isQualified || isProvisional) && (
                <SelectField.Outline
                    disabled={disabled}
                    label={t('eventDrivingLicense.label.licenseClass')}
                    name={`${field}.licenseClass`}
                    options={drivingLicenseClasses}
                />
            )}
            {(isQualified || appliedForInsurance) && (
                <DateField
                    disabled={disabled}
                    label={t('eventDrivingLicense.label.licenseIssueDate')}
                    name={`${field}.issueDate`}
                />
            )}
        </Fragment>
    );
};

LicenseClass.propTypes = {
    appliedForInsurance: PropTypes.bool,
    disabled: PropTypes.bool,
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    field: PropTypes.string.isRequired,
    licenseType: PropTypes.string.isRequired,
};

const LicenseClasses = ({ fields, ...props }) => fields.map(field => <LicenseClass field={field} {...props} />);

LicenseClasses.propTypes = {
    appliedForInsurance: PropTypes.bool,
    disabled: PropTypes.bool,
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
    licenseType: PropTypes.string.isRequired,
};

const LicenseItem = ({
    name,
    disabled: disabledFromProps = false,
    value,
    drivingLicenseValidityCodes,
    drivingLicenseClasses,
    appliedForInsurance,
}) => {
    const { t } = useTranslation();
    // modify driving license options
    const drivingLicenseClassesOptions = useMemo(
        () => drivingLicenseClasses.filter(({ purpose }) => !purpose || purpose.includes(value.source)),
        [drivingLicenseClasses, value.source]
    );
    const drivingLicenseValidityCodesOptions = useMemo(
        () => drivingLicenseValidityCodes.filter(({ purpose }) => !purpose || purpose.includes(value.source)),
        [drivingLicenseValidityCodes, value.source]
    );

    const { sectionPrefix } = useContext(ReduxFormContext);
    // first create memoized watched fields
    const watchedFields = useMemo(
        () => [
            {
                path: name,
                properties: ['type', 'classes', 'validity', 'expiryDate'],
            },
        ],
        [name]
    );

    // get the field state
    const fieldState = useCustomerSource(watchedFields, sectionPrefix);

    // is this field disabled
    const disabled = disabledFromProps || get(name, fieldState);

    // get the license type
    const licenseType = get('type', value);

    // we may have some extra fields depending the type
    const hasExtraFields = [LicenseType.QUALIFIED, LicenseType.PROVISIONAL].includes(licenseType);

    const { code } = useCountry();

    return (
        <>
            <SelectField.Outline
                disabled={disabled}
                label={t('eventDrivingLicense.label.licenseType')}
                name={`${name}.type`}
                options={getLicenseTypeOptions(t)}
                sort={false}
                isFullWidth
            />
            {hasExtraFields && (
                <>
                    <SelectField.Outline
                        disabled={disabled}
                        label={t('eventDrivingLicense.label.licenseValidity')}
                        name={`${name}.validity`}
                        options={drivingLicenseValidityCodesOptions}
                    />
                    <DateField
                        disabled={disabled}
                        label={t('eventDrivingLicense.label.licenseExpiryDate')}
                        name={`${name}.expiryDate`}
                    />
                </>
            )}
            {(hasExtraFields || appliedForInsurance) && code !== 'TH' && (
                <FieldArray
                    appliedForInsurance={appliedForInsurance}
                    component={LicenseClasses}
                    disabled={disabled}
                    drivingLicenseClasses={drivingLicenseClassesOptions}
                    licenseType={licenseType}
                    name={`${name}.classes`}
                />
            )}
        </>
    );
};

LicenseItem.propTypes = {
    appliedForInsurance: PropTypes.bool,
    disabled: PropTypes.bool,
    drivingLicenseClasses: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    drivingLicenseValidityCodes: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
        classes: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
        source: PropTypes.string,
    }).isRequired,
};

const LicenseArray = ({ fields, disabled, appliedForInsurance }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDrivingLicenseClasses());
        dispatch(loadDrivingLicenseValidityCodes());
    }, [dispatch]);

    const drivingLicenseClasses = useSelector(getDrivingLicenseClasses);
    const drivingLicenseValidityCodes = useSelector(getDrivingLicenseValidityCodes);

    const items = fields.map((field, index) => (
        <LicenseItem
            key={field}
            appliedForInsurance={appliedForInsurance}
            disabled={disabled}
            drivingLicenseClasses={drivingLicenseClasses}
            drivingLicenseValidityCodes={drivingLicenseValidityCodes}
            index={index}
            name={field}
            value={fields.get(index)}
        />
    ));

    useEffect(() => {
        if (fields.length < 1) {
            fields.push({
                classes: [{}],
                source: CustomerDetailsSource.MANUAL,
            });
        }
    }, [fields]);

    return <div>{items}</div>;
};

LicenseArray.propTypes = {
    appliedForInsurance: PropTypes.bool,
    disabled: PropTypes.bool,
    fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
};

const DrivingLicense = props => (
    <FieldArray component={LicenseArray} name="details.drivingLicense" rerenderOnEveryChange {...props} />
);

export default DrivingLicense;

// remove error message for required in events
export const drivingLicenseSchema = yup
    .array()
    .of(
        yup.lazy(value => {
            if (get('source', value) === CustomerDetailsSource.MYINFO) {
                return yup.mixed().notRequired();
            }

            const licenseSchema = {
                type: yup.string().required(' '),
            };

            const type = get('type', value);

            switch (type) {
                case LicenseType.QUALIFIED:
                    licenseSchema.classes = yup.array().of(
                        yup.object().shape({
                            licenseClass: yup.string().required(' '),
                        })
                    );
                    licenseSchema.validity = yup.string().required(' ');
                    break;

                case LicenseType.PROVISIONAL:
                    licenseSchema.classes = yup.array().of(
                        yup.object().shape({
                            licenseClass: yup.string().required(' '),
                        })
                    );
                    licenseSchema.validity = yup.string().required(' ');
                    break;

                default:
                    break;
            }

            return yup.object().shape(licenseSchema);
        })
    )
    .nullable()
    .notRequired();
