import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApplicationPhase } from '../../../../schema';
import DealerSelectionDropDown from '../../../ui/DealerSelectionDropDown';
import ApplicationList from './ApplicationList';
import LeadList from './LeadList';

const ListContainer = () => {
    const { t } = useTranslation();

    const [dealerIds, setDealerIds] = useState(null);

    return (
        <>
            <DealerSelectionDropDown onValueChanged={setDealerIds} />
            <ApplicationList
                dealerIds={dealerIds}
                phase={ApplicationPhase.RESERVATION}
                title={t('eventDetailsPage.subHeading.reservationApplications')}
            />
            <ApplicationList
                dealerIds={dealerIds}
                phase={ApplicationPhase.FINANCE}
                title={t('eventDetailsPage.subHeading.financeApplications')}
            />
            <LeadList dealerIds={dealerIds} />
        </>
    );
};

export default ListContainer;
