import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TFunction } from 'i18next';
import { isEmpty, flow, get, pick } from 'lodash/fp';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormErrors, FormSection, getFormSyncErrors, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import * as yup from 'yup';
import { ObjectSchemaDefinition } from 'yup';
import { ConsentDataFragment } from '../../../../../api/consents.graphql';
import { ApplicationCustomerDataFragment } from '../../../../../components/routes/ApplicationRoute/data.graphql';
import FormError from '../../../../../components/shared/form/FormError';
import useCustomerNamesSynchronization from '../../../../../components/shared/useCustomerNamesSynchronization';
import { Error as ErrorMessage } from '../../../../../components/ui/form/FormActions';
import useFormValues from '../../../../../components/utilities/useFormValues';
import { BankPresetOption, UploadPurpose } from '../../../../../schema';
import { yupExt } from '../../../../../utilities/forms';
import { FileInput } from '../../../../utils/uploads';
import { Application } from '../../../types';
import { Buttons, PrimaryButton, SecondaryButton } from '../../Calculator/ui';
import DraftModalProvider from '../../DraftPage/DraftModalProvider';
import { PageTitle, Title } from '../ui';
import ApplicantDetails, {
    detailsSchema as applicantDetailsSchema,
    rootSchema as applicantRootSchema,
    affinBankSchema as applicantAffinBankSchema,
    getApplicantSchema,
} from './ApplicantDetails';
import { ConsentForm, schema as consentFormSchema } from './ConsentForm';
import ContactDetails, {
    affinBankSchema as contactDetailAffinBankSchema,
    detailsSchema as contactDetailsSchema,
} from './ContactDetails';
import CustomerInformationForm, { customerFilesSchema, rootSchema as customerRootSchema } from './CustomerDetails';
import OccupationForm, { detailsSchema as occupationDetailsSchema, occupationFilesSchema } from './OccupationDetails';
import useSaveDraftValidation from './useSaveDraftValidation';
import { filesSchema } from './validateFile';

export type PageProps = {
    apply: (values: any, validate: (values: any) => void) => Promise<any>;
    countryCode?: string;
    useCustomerBirthDay: boolean;
    useCustomerNationality: boolean;
    step: string;
    initialValues: any;
    validation: any;
    application: Application;
    consents: ConsentDataFragment[];
    onSaveDraft: (values: CustomerFormValues) => Promise<void>;
};

export type CustomerFormValues = {
    customerInfo: Partial<ApplicationCustomerDataFragment> & {
        files?: FileInput[];
        occupationFiles?: FileInput[];
        applicantFiles?: FileInput[];
        customerFiles?: FileInput[];
    };
    consentDraft?: { consents: Record<string, boolean>; referenceId: undefined };
    draftTo?: string;
};

const CustomerForm = (props: PageProps & InjectedFormProps<CustomerFormValues, PageProps>) => {
    const { valid, handleSubmit, change, consents, application, form, onSaveDraft, step } = props;
    const { t } = useTranslation();
    const values = useFormValues<CustomerFormValues>();
    const { consentDraft } = values;
    const { consents: consentStatuses } = consentDraft ?? {};
    // update choices for test drive and trade in if checkboxes are in current page
    const onNormalizeNames = useCustomerNamesSynchronization('customerInfo');

    // only save draft only when not proceed with customer
    // and has not submitted kyc yet
    const hasSaveDraft = useMemo(() => !application.proceedWithCustomerDevice && !application.steps?.kyc, [
        application,
    ]);

    const errors = useSelector(getFormSyncErrors(form));

    const validate = useSaveDraftValidation(saveDraftSchema, application);

    const canSaveDraft = flow([get('consentDraft'), isEmpty])(errors);

    const bankPresetOption = application.financeProduct?.bank?.presetOption;

    const hasConsents = !!consents?.length;

    return (
        <DraftModalProvider change={change} handleSubmit={() => onSaveDraft(values)}>
            {setDraftModal => (
                <>
                    <PageTitle>
                        {step === 'guarantor' ? t('guarantorPage.label.step') : t('kycPage.applicantDetailsTitle')}
                    </PageTitle>
                    <div className="row">
                        <div className="col-md-8 col-sm-12 col-xs-12">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12 mb-5">
                                    <FormSection name="customerInfo">
                                        <CustomerInformationForm
                                            onNormalizeNames={onNormalizeNames}
                                            step={step}
                                            withFiles
                                        />
                                    </FormSection>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12 mb-5">
                                    <FormSection name="customerInfo">
                                        <OccupationForm step={step} withFiles />
                                    </FormSection>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12 mb-5">
                                    <FormSection name="customerInfo">
                                        <ApplicantDetails bankPresetOption={bankPresetOption} step={step} />
                                    </FormSection>
                                </div>
                                <div className="col-md-6 col-sm-12 col-xs-12 mb-5">
                                    <FormSection name="customerInfo">
                                        <ContactDetails bankPresetOption={bankPresetOption} step={step} />
                                    </FormSection>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12 mb-5">
                            {hasConsents && (
                                <FormSection name="consentDraft">
                                    <Title>{t('consentPage.title')}</Title>
                                    <ConsentForm
                                        change={change}
                                        consentStatuses={consentStatuses}
                                        consents={consents}
                                    />
                                </FormSection>
                            )}
                            <Buttons>
                                <PrimaryButton disabled={!valid} onClick={handleSubmit}>
                                    <FontAwesomeIcon icon={faAngleRight} /> {t('kycPage.button.next')}
                                </PrimaryButton>
                                {hasSaveDraft && (
                                    <SecondaryButton
                                        disabled={!canSaveDraft}
                                        onClick={() => {
                                            if (validate()) {
                                                setDraftModal(true);
                                            }
                                        }}
                                        type="button"
                                    >
                                        <FontAwesomeIcon icon={faAngleRight} /> {t('kycPage.button.save')}
                                    </SecondaryButton>
                                )}
                            </Buttons>
                        </div>
                    </div>
                    <FormError form={form}>
                        {(error: FormErrors<{}, string>) => <ErrorMessage>{error}</ErrorMessage>}
                    </FormError>
                </>
            )}
        </DraftModalProvider>
    );
};

// remove error message for required in events
export const schema = () => (t: TFunction) => {
    const shape: ObjectSchemaDefinition<any> = {
        consentDraft: consentFormSchema(t),
        customerInfo: yupExt.customerProperty().shape({
            ...applicantRootSchema(t),
            customerFiles: customerFilesSchema(t),
            ...customerRootSchema(t),
            // @ts-ignore
            details: yup.lazy((value, options) => {
                const isAffinBank =
                    get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.AFFINBANK;

                const applicantSchema = getApplicantSchema(
                    get('context.application.financeProduct.bank.presetOption', options),
                    t
                );

                return yupExt.customerProperty().shape({
                    ...applicantSchema,
                    ...occupationDetailsSchema(t),
                    ...contactDetailsSchema(t),
                    thirdParty: yupExt.customerProperty().shape({
                        affinBank: yup.lazy(() => {
                            if (isAffinBank) {
                                return yupExt.customerProperty().shape({
                                    ...applicantAffinBankSchema(t),
                                    ...contactDetailAffinBankSchema(t),
                                });
                            }

                            return yup.mixed().notRequired();
                        }),
                    }),
                });
            }),
            occupationFiles: occupationFilesSchema(t),
        }),
    };

    return yup.object().shape(shape);
};

const saveDraftSchema = (t: TFunction) => {
    const shape: ObjectSchemaDefinition<any> = {
        customerInfo: yupExt.customerProperty().shape({
            ...applicantRootSchema(t),
            ...customerRootSchema(t),
            customerFiles: filesSchema({ min: 0, max: 5 }, UploadPurpose.CUSTOMER_IDENTITY, t),

            // @ts-ignore
            details: yup.lazy((value, options) => {
                const isTTBBank =
                    get('context.application.financeProduct.bank.presetOption', options) === BankPresetOption.TTBBANK;

                const applicantSchema = isTTBBank ? {} : pick(['nationality'], applicantDetailsSchema(t));

                return yupExt.customerProperty().shape({
                    ...applicantSchema,
                    employment: yupExt.customerProperty().shape({
                        assessmentYear: yupExt.customerProperty().shape({
                            value: yup
                                .string()
                                .matches(/^[0-9]+$/, t('common.error.digitsOnly'))
                                .min(4, t('common.error.exactDigits', { count: 4 }))
                                .max(4, t('common.error.exactDigits', { count: 4 })),
                        }),
                        employer: yupExt.customerProperty().shape({
                            value: yup.string().max(124, t('common.error.lessThanOrEqual', { max: 124 })),
                        }),
                        occupation: yupExt.customerProperty().shape({
                            value: yup.string().max(100, t('common.error.lessThanOrEqual', { max: 100 })),
                        }),
                    }),
                    residentialAddress: yupExt.customerProperty().shape({
                        block: yup
                            .string()
                            .nullable()
                            .notRequired()
                            .max(10, t('common.error.lessThanOrEqual', { max: 10 })),
                        building: yup
                            .string()
                            .nullable()
                            .notRequired()
                            .max(66, t('common.error.lessThanOrEqual', { max: 66 })),
                        postalCode: yup.string().max(6, t('common.error.lessThanOrEqual', { max: 6 })),
                        street: yup
                            .string()
                            .nullable()
                            .notRequired()
                            .max(66, t('common.error.lessThanOrEqual', { max: 66 })),
                        unit: yup.string().max(10, t('common.error.lessThanOrEqual', { max: 10 })),
                    }),
                });
            }),
            // @ts-ignore
            occupationFiles: filesSchema({ min: 0, max: 5 }, UploadPurpose.CUSTOMER_ATTACHED, t),
        }),
    };

    return yup.object().shape(shape);
};

export default reduxForm<CustomerFormValues, PageProps>({
    form: 'customer',
    // @ts-ignore
    onSubmit: (values, dispatch, props) => {
        const { validate, apply } = props;

        // @ts-ignore
        return apply({ ...values }, nextValues => {
            if (!validate) {
                return;
            }
            const errors = validate(nextValues, props);
            if (!isEmpty(errors)) {
                throw new SubmissionError(errors);
            }
        });
    },
    // Turn this ON, to enable rerender when initialValues changed
    // Because when myInfo is OFF, and guarantor is ON, in Main flow
    // The form still using initial "customer" values instead of "guarantor" values
    enableReinitialize: true,
})(CustomerForm);
