import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxInput } from '../../../../../components/shared/form/CheckboxField';
import InfoTooltip from '../../../../../components/ui/tooltip/InfoTooltip';
import { Channel } from '../../../../../schema';
import { CheckboxWrapper, SectionItem } from './styles';

type Tooltip = {
    title?: string;
    content?: string;
};

const OptionalCalculatorLabel = () => {
    const { t } = useTranslation();

    const tooltip: Tooltip = t('eventCalculatorPage.financeCalculator', { returnObjects: true });

    const hasTooltip = tooltip?.title && tooltip?.content;

    return (
        <span style={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'center' }}>
            {t(`eventCalculatorPage.checkbox.financeCalculator`)}
            {hasTooltip && <InfoTooltip content={tooltip.content} title={tooltip.title} />}
        </span>
    );
};

type SectionOptionalCalculatorProps = {
    change: (name: string, value: boolean) => void;
    initialValue?: boolean;
};

const SectionOptionalCalculator = ({ change, initialValue = false }: SectionOptionalCalculatorProps) => {
    const [isChecked, setChecked] = useState(initialValue);

    const currentValueRef = useRef(initialValue);

    const handleChange = useCallback(
        (newValue: boolean) => {
            setChecked(newValue);

            change('isCalculatorEnabled', newValue);
            change('appliedForFinancing', newValue);
        },
        [change]
    );

    useEffect(() => {
        if (currentValueRef.current !== initialValue) {
            handleChange(initialValue);
            currentValueRef.current = initialValue;
        }
    }, [handleChange, initialValue]);

    return (
        <SectionItem>
            <CheckboxWrapper>
                <CheckboxInput
                    channel={Channel.EVENT}
                    input={{
                        name: 'hasOptionalCalculator',
                        onChange: handleChange,
                        value: isChecked,
                    }}
                    label={<OptionalCalculatorLabel />}
                />
            </CheckboxWrapper>
        </SectionItem>
    );
};

export default SectionOptionalCalculator;
