import { useQuery } from '@apollo/client';
import { Paging, TableList, Portlet, Footer } from '@appvantageasia/afc-ui';
import { flow, get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { ReduxFormContext, getFormValues } from 'redux-form';
import styled from 'styled-components';
import { exportEventsApplication } from '../../../../actions/export';
import { useZone } from '../../../../hookSelectors';
import { useContentTranslation } from '../../../../i18n';
import { EventExternalSite } from '../../../../schema';
import { getApplicationUrl } from '../../../../utilities/urls';
import useFormatDateTime from '../../../shared/useFormatDateTime';
import Status from '../../../ui/ApplicationStatus';
import { renderCurrencyDown } from '../../../ui/Cells';
import { Content } from '../../../ui/PageContainer';
import useCompanyFormatting from '../../../utilities/useCompanyFormatting';
import { getTransactionId } from '../../ApplicationsRoute';
import { getSortOrderEnum } from '../../AppointmentRoute/List/AppointmentListRoute';
import Download from './Download';
import { withEventApplications } from './EventApplicationsContext';
import { getEventApplicationsByZoneId } from './data.graphql';
import { useReducerActions } from './reducer';

export const Checkbox = styled.input`
    width: 15px;
    height: 15px;
`;

export const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

const { FootBarButton, FootBarRow, FootBar } = Footer;

const renderStatus = () => ({ statusText: { label, color } }) => <Status color={color}>{label}</Status>;

const useColumns = (name, onCheckboxChange, isAllCheckboxSelected, hasBank, hasFinanceProduct, hasLoan, checkedIds) => {
    const { t } = useTranslation();
    const { formatCurrencyDown } = useCompanyFormatting();
    const formatDateTime = useFormatDateTime();
    const { formatPath } = useContentTranslation();
    const history = useHistory();

    return useMemo(() => {
        const columns = [
            {
                key: 'checkBox',
                label: '',
                showCheckbox: true,
                getValue: item => (
                    <CheckboxContainer>
                        <Checkbox
                            checked={isAllCheckboxSelected || checkedIds.includes(item.version.id)}
                            onChange={() => onCheckboxChange(item.version.id)}
                            type="checkbox"
                        />
                    </CheckboxContainer>
                ),
                checkBox: (
                    <CheckboxContainer>
                        <Checkbox
                            checked={isAllCheckboxSelected}
                            onChange={() => onCheckboxChange('all')}
                            type="checkbox"
                        />
                    </CheckboxContainer>
                ),
            },
            {
                key: 'applicationDate',
                sortKey: 'version.createdAt',
                label: t('eventDetailsPage.label.applicationDate'),
                getValue: flow([get('version.createdAt'), formatDateTime]),
            },
            {
                key: 'appId',
                sortKey: 'identifier',
                label: t('eventDetailsPage.label.applicationID'),
                getValue: get('identifier'),
                underlined: true,
                onClick: (event, item) => {
                    event.stopPropagation();
                    history.pushWithCompanyAndState(getApplicationUrl, { previous: `Event ${name}` }, item);
                },
            },
            {
                key: 'dealer',
                sortKey: formatPath('dealer.name'),
                label: t('eventDetailsPage.label.dealer'),
                getValue: get(formatPath('dealer.name')),
            },
            {
                key: 'salePerson',
                sortKey: 'assignee.name',
                label: t('eventDetailsPage.label.salesPerson'),
                getValue: get('assignee.name'),
            },
            {
                key: 'customer',
                sortKey: 'customer.name.value',
                label: t('eventDetailsPage.label.customer'),
                getValue: get('customer.name.value'),
            },
            {
                key: 'variant',
                sortKey: formatPath('variant.name'),
                label: t('eventDetailsPage.label.variant'),
                getValue: get(formatPath('variant.name')),
            },
            hasBank && {
                key: 'bank',
                sortKey: 'bank.name',
                label: t('eventDetailsPage.label.bank'),
                getValue: get(formatPath('bank.name')),
            },
            hasFinanceProduct && {
                key: 'financeProduct',
                sortKey: 'financeProduct.name',
                label: t('eventDetailsPage.label.financialProduct'),
                getValue: get(formatPath('financeProduct.name')),
            },
            hasLoan && {
                key: 'loan',
                sortKey: 'calculator.loan.amount',
                label: t('eventDetailsPage.label.loan'),
                getValue: renderCurrencyDown('calculator.loan.amount', formatCurrencyDown),
            },
            {
                key: 'status',
                sortKey: 'status',
                label: t('eventDetailsPage.label.status'),
                getValue: renderStatus(),
            },
            {
                key: 'transactionID',
                sortKey: 'transactionID',
                label: 'Transaction ID',
                getValue: get('transactionID'),
            },
            {
                key: 'lastActivity',
                sortKey: 'version.updatedAt',
                label: t('eventDetailsPage.label.lastActivity'),
                getValue: flow([get('version.updatedAt'), formatDateTime]),
            },
        ];

        return columns.filter(Boolean);
    }, [
        checkedIds,
        formatCurrencyDown,
        formatDateTime,
        formatPath,
        hasBank,
        hasFinanceProduct,
        hasLoan,
        history,
        isAllCheckboxSelected,
        name,
        onCheckboxChange,
        t,
    ]);
};

const ApplicationList = ({
    dealerIds,
    title,
    phase,
    eventApplicationsDispatch: reducerDispatch,
    eventApplicationsState: reducerState,
}) => {
    const { t } = useTranslation();
    const zone = useZone();
    const { language } = useContentTranslation();
    const { form } = useContext(ReduxFormContext);
    const { name, identifier, id } = useSelector(getFormValues(form)) || {};

    const { data } = useQuery(getEventApplicationsByZoneId, {
        fetchPolicy: 'cache-and-network',
        variables: {
            id: zone.id,
            filter: {
                dealerIds: dealerIds?.length ? dealerIds : [],
                eventIds: id ? [id] : undefined,
                phase,
            },
            paging: {
                limit: reducerState.paging.pageSize,
                offset: (reducerState.paging.page - 1) * reducerState.paging.pageSize,
            },
            sorting: [
                {
                    type: reducerState.sortedOn.type,
                    order: getSortOrderEnum(reducerState.sortedOn.order),
                },
            ],
            locale: language,
        },
    });

    const { pagingProps, sorting, onCheckboxChange } = useReducerActions(
        reducerState,
        reducerDispatch,
        data?.results?.count ?? 0
    );

    const applicationData = useMemo(() => {
        if (!data || !data?.results?.count) {
            return [];
        }

        return data?.results?.items?.map(application => ({
            ...application,
            transactionID: getTransactionId(application),
            variant:
                application.event?.setting.externalSite === EventExternalSite.MARKETINGRECONSENT
                    ? null
                    : application.variant,
        }));
    }, [data]);

    const [downloadModal, setDownloadModal] = useState(false);
    const dispatch = useDispatch();
    const location = useParams();

    // check bank and FinanceProduct exist inside application
    const { hasBank, hasFinanceProduct, hasLoan } = useMemo(
        () => ({
            hasFinanceProduct: applicationData.some(application => application.financeProduct),
            hasBank: applicationData.some(application => application.bank.name),
            hasLoan: applicationData.some(application => application.calculator.loan),
        }),
        [applicationData]
    );

    const columns = useColumns(
        name,
        onCheckboxChange,
        reducerState.isCheckedAll,
        hasBank,
        hasFinanceProduct,
        hasLoan,
        reducerState.checkedIds
    );

    const closeDownloadModal = useCallback(() => setDownloadModal(null), [setDownloadModal]);

    const openDownloadModal = useCallback(
        password => setDownloadModal(<Download onClose={closeDownloadModal} password={password} type="application" />),
        [closeDownloadModal]
    );

    const download = useCallback(() => {
        openDownloadModal();
        dispatch(
            exportEventsApplication(location.id, identifier, reducerState.checkedIds, phase, dealerIds, language)
        ).then(password => {
            if (password) {
                openDownloadModal(password);
            } else {
                setDownloadModal(null);
            }
        });
    }, [dealerIds, dispatch, identifier, language, location.id, openDownloadModal, phase, reducerState.checkedIds]);

    if (!pagingProps.itemCount) {
        return null;
    }

    return (
        <Portlet name={title} closable open>
            <div className="container-fluid">
                <Content>
                    <TableList columns={columns} items={applicationData} sorting={sorting} />
                </Content>
                <Paging {...pagingProps} />
                {downloadModal}
                <FootBar />
                <FootBarRow>
                    <div style={{ flex: 1 }} />
                    <FootBarButton
                        disabled={reducerState.isCheckedAll ? false : reducerState.checkedIds.length === 0}
                        onClick={download}
                    >
                        {t('eventDetailsPage.button.download')}
                    </FootBarButton>
                </FootBarRow>
            </div>
        </Portlet>
    );
};

ApplicationList.propTypes = {
    dealerIds: PropTypes.arrayOf(PropTypes.string),
    eventApplicationsDispatch: PropTypes.func,
    eventApplicationsState: PropTypes.shape({}),
    phase: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default withEventApplications(ApplicationList);
