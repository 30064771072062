import { CalculatorContext } from '@appvantageasia/afc-calculator-ui-next';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEmpty, isNil } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form';
import { v4 as uuid } from 'uuid';
import useLoadPromo from '../../../../../components/data/useLoadPromo';
import { VariantDataFragment } from '../../../../../components/data/useLoadVariants.graphql';
import { EventDataFragment } from '../../../../../components/routes/EventRoute/EventRoute.graphql';
import { FinderVehicleDataFragment } from '../../../../../components/routes/FinderRoute/data.graphql';
import { ConnectedCalculatorProps } from '../../../../../components/shared/calculator-next/ConnectedCalculator';
import { InsuranceCalculatorErrors } from '../../../../../components/shared/calculator-next/InsuranceCalculator';
import { CalculatorInsuranceValues, CalculatorValues } from '../../../../../components/shared/calculator-next/types';
import useCalculatorMeta from '../../../../../components/shared/calculator-next/useCalculatorMeta';
import ReservationDepositField from '../../../../../components/shared/calculator/ReservationDepositField';
import CheckboxField from '../../../../../components/shared/form/CheckboxField';
import { CalculatorError } from '../../../../../components/ui/calculator';
import useCalculatorData from '../../../../../components/utilities/useCalculatorData';
import useCompanyFormatting from '../../../../../components/utilities/useCompanyFormatting';
import { useCountry, useZone } from '../../../../../hookSelectors';
import { useContentTranslation } from '../../../../../i18n';
import { CalculatorVisibility, Channel, PromoCodeUnit } from '../../../../../schema';
import { CalculatorErrors } from '../../../../DraftFlow';
// eslint-disable-next-line max-len
import useFinanceProductRefinements from '../../../../DraftFlow/components/ExpressCalculator/useFinanceProductRefinements';
// eslint-disable-next-line max-len
import useRefineCalculatorSnapshot from '../../../../DraftFlow/components/ExpressCalculator/useRefineCalculatorSnapshot';
import useRefineFinanceProducts from '../../../../DraftFlow/components/ExpressCalculator/useRefineFinanceProducts';
import { CalculatorSnapshot } from '../../../../utils/getSnapshotFromApplication';
import useDealerEstablishment from '../../../../utils/useDealerEstablishment';
import { Buttons, Container, Paragraph, PrimaryButton, Title } from '../ui';
import useApplyDisabled from '../useApplyDisabled';
import { DisclaimerEventItem } from './Disclaimer';
import SectionFinance from './SectionFinance';
import SectionInsurance from './SectionInsurance';
import SectionOptionalCalculator from './SectionOptionalCalculator';
import SectionTradeIn from './SectionTradeIn';
import { CheckboxWrapper, Description, Price, PriceContainer, SectionItem, SectionWrapper, SubHeading } from './styles';

type CalculatorChangeFunction = <Field extends 'getFieldContext' | keyof CalculatorValues | 'unitId'>(
    target: Field,
    value: CalculatorValues[Field] | undefined
) => void;

export type FinancingProps = {
    dealerId: string;
    calculatorValues?: Partial<CalculatorValues>;
    initialCalculatorValues: Partial<CalculatorValues>;
    snapshot?: CalculatorSnapshot;
    onCalculatorChange: ConnectedCalculatorProps['onChange'];
    onInsuranceCalculatorChange: (context: CalculatorContext<CalculatorInsuranceValues>) => void;
    calculatorErrors?: Partial<CalculatorErrors>;
    variant: VariantDataFragment;
    variants: VariantDataFragment[];
    event: EventDataFragment;
    finderVehicle?: FinderVehicleDataFragment | null;
    insuranceCalculatorErrors?: Partial<CalculatorErrors>;
};

export type FinanceData = {
    hasTestDrive?: boolean;
    hasTradeIn?: boolean;
    isCalculatorEnabled?: boolean;
    applyForFinancing?: boolean;
    applyForInsurance?: boolean;
};

const Financing = ({
    dealerId,
    calculatorValues,
    initialCalculatorValues,
    snapshot,
    onCalculatorChange,
    onInsuranceCalculatorChange,
    handleSubmit,
    calculatorErrors,
    variant,
    variants,
    event,
    change,
    form,
    finderVehicle,
    insuranceCalculatorErrors,
}: FinancingProps & InjectedFormProps<FinanceData, FinancingProps>) => {
    const { t } = useTranslation();
    const { ct } = useContentTranslation();

    const [remoteNode, setRemoteNode] = useState<HTMLElement | null>(null);

    const calculatorRefChange = useRef<CalculatorChangeFunction>(() => null);

    const [ignoreInsuranceCalculator, setIgnoreInsuranceCalculator] = useState(false);

    // get the text footer from the zone
    const { id: zoneId } = useZone();

    // get countryId for loading promo
    const country = useCountry();
    const { id: countryId } = country;

    const { formatCurrency } = useCompanyFormatting();

    const { financeProducts } = useCalculatorData(Channel.EVENT, dealerId, undefined, event.id);
    const { selectedDealerEstablishment } = useDealerEstablishment(dealerId as string, Channel.EVENT);

    // There is visibility optional, so listen to value change
    const valueSelector = useCallback(state => getFormValues(form)(state), [form]);
    const formValues = useSelector<FinanceData>(valueSelector);

    const isCalculatorEnabled = get('isCalculatorEnabled', formValues) as boolean;
    const hasTradeIn = get('hasTradeIn', formValues) as boolean;

    useEffect(() => {
        if (isNil(isCalculatorEnabled)) {
            const visibility = event.setting.calculatorVisibility;

            const newValue = isNil(visibility) ? true : visibility === CalculatorVisibility.ON;

            change('isCalculatorEnabled', newValue);
            if (visibility === CalculatorVisibility.OPTIONAL) {
                change('appliedForFinancing', false);
            }
        }
    }, [isCalculatorEnabled, change, event.setting.calculatorVisibility]);

    // we need to render promo code outside the calculator
    // so we can create a div with an unique id
    const promoContainerId = useMemo(uuid, []);

    const applyDisabled = useApplyDisabled(calculatorValues || {}, isCalculatorEnabled);

    const price = variant.prices.find(item => item.zoneId === zoneId)?.value;

    const promo = useLoadPromo(countryId, dealerId, calculatorValues?.promoCode);
    const isPromoValid = promo?.deductValue && promo?.remainingQuantity > 0;

    const promoAmount = useMemo(() => {
        if (promo && promo.value) {
            switch (promo.unit) {
                case PromoCodeUnit.PERCENTAGE: {
                    if (!price) {
                        return 0;
                    }

                    return price * (promo.value / 100);
                }
                case PromoCodeUnit.CURRENCY:
                    return promo.value;
                default:
                    return undefined;
            }
        }

        return 0;
    }, [promo, price]);

    const {
        allowTradeIn,
        allowTestDrive,
        allowTradeInAmountInput,
        isPromoCodeEnabled,
        bookingPayment,
        isCoeEnabled,
        isCoeEditable,
        isPpsrAndEstablishmentEnabled,
        priceDisclaimers,
        applyForFinancing,
        isInsuranceEnabled,
        financePriceDisclaimers,
        insurancePriceDisclaimers,
    } = event.setting;

    const usedCoeAmount = useMemo(() => calculatorValues?.coe ?? country?.coe?.amount, [calculatorValues, country]);

    const priceIncludeCoe = (price ?? 0) - (isPromoValid ? promoAmount ?? 0 : 0) + (usedCoeAmount ?? 0);

    const priceElement = (
        <Price isCoeEnabled={isCoeEnabled} isPromoValid={isPromoValid} promo={promoAmount}>
            <span>{t('eventCalculatorPage.label.price')}</span>
            <span>{formatCurrency(price)}</span>
        </Price>
    );

    const promoElement =
        promoAmount && isPromoValid ? (
            <Price isCoeEnabled={isCoeEnabled}>
                <span>
                    {t(
                        isCoeEnabled
                            ? 'eventCalculatorPage.label.priceWithoutCoe'
                            : 'eventCalculatorPage.label.finalPrice'
                    )}
                </span>
                <span>{price && formatCurrency(price - promoAmount)}</span>
            </Price>
        ) : null;

    const estimatedCoeElement = isCoeEnabled && (
        <Price isCoeEnabled={isCoeEnabled}>
            <span>{t('eventCalculatorPage.label.estimatedCoe')}</span>
            <span>{formatCurrency(usedCoeAmount)}</span>
        </Price>
    );

    const priceWithCoeElement = isCoeEnabled && (
        <Price isCoeEnabled={isCoeEnabled} isLast>
            <span>{t('eventCalculatorPage.label.priceIncludeCoe')}</span>
            <span>{price && formatCurrency(priceIncludeCoe)}</span>
        </Price>
    );

    // Handling calculation when there is no calculator
    useEffect(() => {
        if (!isCalculatorEnabled) {
            change('noCalculator.totalPrice', priceIncludeCoe);
            change('noCalculator.carPrice', price);
            change('noCalculator.coe', usedCoeAmount);
        }
    }, [change, priceIncludeCoe, price, isCalculatorEnabled, usedCoeAmount]);

    const dealer = useMemo(() => event?.dealers?.find(dealer => dealer.id === dealerId), [event, dealerId]);

    const hasDepositPayment = !!event?.setting?.isDepositPaymentMandatory;
    const paymentAmount = bookingPayment?.amount;

    const monthlyPayment = calculatorValues?.monthlyInstalments
        ? calculatorValues.monthlyInstalments[0].amount
        : undefined;

    const descriptionElement = (
        <>
            <Title>{ct(variant.name)}</Title>
            <SubHeading>
                <Trans i18nKey="eventCalculatorPage.subHeading" values={{ name: ct(dealer?.name) }} />
            </SubHeading>
            <Paragraph>{ct(variant.description)}</Paragraph>
        </>
    );

    const [totalPrice, setTotalPrice] = useState<number | undefined>(undefined);
    const financeProductRefinements = useFinanceProductRefinements(finderVehicle?.details);
    const refinedSnapshot = useRefineCalculatorSnapshot(financeProductRefinements, snapshot, totalPrice);
    const refinedFinanceProducts = useRefineFinanceProducts(
        financeProducts,
        financeProductRefinements,
        variant?.version?.id,
        totalPrice
    );

    const appliedForFinancing = get('appliedForFinancing', formValues) as boolean;
    const appliedForInsurance = get('appliedForInsurance', formValues) as boolean;

    const [insuranceErrors, setInsuranceErrors] = useState<null | InsuranceCalculatorErrors>(null);

    const onCalculatorChangeEnhanced = useCallback(
        (context: CalculatorContext<CalculatorValues>) => {
            if (context.values.totalPrice) {
                setTotalPrice(context.values.totalPrice);
            }

            // Bind it to our ref
            calculatorRefChange.current = context.change as CalculatorChangeFunction;

            if (onCalculatorChange) {
                onCalculatorChange(context);
            }
        },
        [onCalculatorChange]
    );

    const selectedBankEstablishment = financeProducts
        ? financeProducts.find(fp => fp.dealerIds.includes(dealerId))?.bank.establishment
        : null;

    // the finance product is not yet setup
    const invalidCalculatorSetup = useMemo(
        () => !financeProducts.some(({ finderVehicles }) => finderVehicles.includes(variant.version.id)),
        [financeProducts, variant]
    );

    const { insuranceHasErrors, insuranceErrorMessages } = useMemo(
        () => ({
            insuranceHasErrors: !isEmpty(insuranceErrors) || !isEmpty(insuranceCalculatorErrors),
            insuranceErrorMessages: !isEmpty(insuranceCalculatorErrors) ? insuranceCalculatorErrors : insuranceErrors,
        }),
        [insuranceCalculatorErrors, insuranceErrors]
    );

    const additionalMeta = useMemo(
        () => ({
            snapshot: refinedSnapshot,
            channel: Channel.EVENT,
            variants,
            isPromoCodeEnabled,
            isCoeEnabled,
            isCoeEditable,
            isPpsrAndEstablishmentEnabled,
            financeProducts: refinedFinanceProducts,
            allowTradeInAmountInput,
            selectedDealerEstablishment,
            isInitiallyEmpty: true,
        }),
        [
            refinedSnapshot,
            variants,
            isPromoCodeEnabled,
            isCoeEnabled,
            isCoeEditable,
            isPpsrAndEstablishmentEnabled,
            refinedFinanceProducts,
            allowTradeInAmountInput,
            selectedDealerEstablishment,
        ]
    );
    const meta = useCalculatorMeta(additionalMeta);

    const handleInsurancePremiumChange = useCallback(({ errors }: { errors: InsuranceCalculatorErrors }) => {
        setInsuranceErrors(errors);
    }, []);

    // This only applied for calculator visibility ON
    const showFinanceCheckbox = useMemo(() => {
        const isVehiclePreowned = finderVehicle?.listing?.vehicle.condition?.value?.toLowerCase() !== 'new';
        const isVehiclePreownedAndHasLta = isVehiclePreowned && !!finderVehicle?.details;

        return (
            applyForFinancing &&
            event.setting.calculatorVisibility === CalculatorVisibility.ON &&
            ((country.code === 'SG' && (isVehiclePreownedAndHasLta || !isVehiclePreowned)) || country.code !== 'SG')
        );
    }, [applyForFinancing, country.code, event.setting.calculatorVisibility, finderVehicle]);

    useEffect(() => {
        // Only update the settings, when the calculator is enabled
        // Because optional calculator will have it's own checkbox
        if (!showFinanceCheckbox && event.setting.calculatorVisibility !== CalculatorVisibility.OPTIONAL) {
            change('appliedForFinancing', false);
        }
    }, [change, event.setting.calculatorVisibility, showFinanceCheckbox]);

    useEffect(() => {
        // After page loaded we need to update the calculator values
        // based checkbox is ticked
        if (calculatorRefChange.current) {
            calculatorRefChange.current?.('isFinancingEnabled', appliedForFinancing);
        }
    }, [appliedForFinancing]);

    // Initialize with the value from the form
    const enhancedInitialCalculatorValues = useMemo(
        () => ({
            ...initialCalculatorValues,
            isFinancingEnabled: appliedForFinancing,
        }),
        [appliedForFinancing, initialCalculatorValues]
    );

    const shownCalculator = showFinanceCheckbox ? appliedForFinancing : isCalculatorEnabled;

    const showTradeInAmountInput = hasTradeIn && allowTradeInAmountInput;

    return (
        <Container>
            <Description>{descriptionElement}</Description>
            <PriceContainer>
                {priceElement}
                {promoElement}
                {estimatedCoeElement}
                {priceWithCoeElement}
            </PriceContainer>
            {!!priceDisclaimers?.length &&
                (isCalculatorEnabled || event.setting.calculatorVisibility === CalculatorVisibility.OPTIONAL) && (
                    <DisclaimerEventItem
                        priceDisclaimers={priceDisclaimers}
                        selectedBankEstablishment={selectedBankEstablishment}
                        selectedDealerEstablishment={selectedDealerEstablishment}
                    />
                )}
            <SectionWrapper>
                <SectionFinance
                    appliedForFinancing={appliedForFinancing}
                    dealerId={dealerId}
                    financeDisclaimers={financePriceDisclaimers}
                    formattedMonthlyPayment={formatCurrency(monthlyPayment)}
                    hasCalculator={shownCalculator}
                    hasFinancingCheckbox={showFinanceCheckbox}
                    hasTradeInInput={showTradeInAmountInput}
                    initialCalculatorValues={enhancedInitialCalculatorValues}
                    isInvalidCalculatorSetup={invalidCalculatorSetup}
                    meta={meta}
                    onCalculatorChanged={onCalculatorChangeEnhanced}
                    promoContainerId={promoContainerId}
                    selectedBankEstablishment={selectedBankEstablishment}
                    showMonthlyPayment={
                        isCalculatorEnabled || event.setting.calculatorVisibility === CalculatorVisibility.OPTIONAL
                    }
                    tradeInContainerNode={remoteNode}
                />
                {isInsuranceEnabled && (
                    <SectionInsurance
                        appliedForInsurance={appliedForInsurance}
                        carPrice={price}
                        dealerId={dealerId}
                        finderVehicle={finderVehicle}
                        ignoreInsuranceCalculator={ignoreInsuranceCalculator}
                        initialCalculatorValues={initialCalculatorValues}
                        insuranceDisclaimers={insurancePriceDisclaimers}
                        meta={meta}
                        onInsuranceCalculatorChange={onInsuranceCalculatorChange}
                        onInsurancePremiumChange={handleInsurancePremiumChange}
                        selectedBankEstablishment={selectedBankEstablishment}
                        setIgnoreInsuranceCalculator={setIgnoreInsuranceCalculator}
                        variants={variants}
                    />
                )}
                {allowTradeIn && (
                    <SectionTradeIn ref={node => setRemoteNode(node)} hasTradeIn={showTradeInAmountInput} />
                )}
                <SectionItem>
                    {allowTestDrive && (
                        <CheckboxWrapper>
                            <CheckboxField
                                channel={Channel.EVENT}
                                label={t(`eventCalculatorPage.checkbox.testDrive`)}
                                name="hasTestDrive"
                            />
                        </CheckboxWrapper>
                    )}
                </SectionItem>
                {event.setting.calculatorVisibility === CalculatorVisibility.OPTIONAL && (
                    <SectionOptionalCalculator change={change} initialValue={isCalculatorEnabled} />
                )}
            </SectionWrapper>

            <div id={promoContainerId} />
            {hasDepositPayment && <ReservationDepositField amount={paymentAmount} channel={Channel.EVENT} />}
            <Buttons>
                <PrimaryButton
                    disabled={
                        applyDisabled || (insuranceHasErrors && appliedForInsurance && !ignoreInsuranceCalculator)
                    }
                    onClick={handleSubmit}
                >
                    <FontAwesomeIcon icon={faAngleRight} /> {t('eventCalculatorPage.button.next')}
                </PrimaryButton>
            </Buttons>
            {isCalculatorEnabled && !invalidCalculatorSetup && <CalculatorError errors={calculatorErrors} />}
            {appliedForInsurance && insuranceHasErrors && !ignoreInsuranceCalculator && (
                <CalculatorError errors={insuranceErrorMessages} />
            )}
        </Container>
    );
};

export default reduxForm<FinanceData, FinancingProps>({ form: 'financing' })(Financing);
