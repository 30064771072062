import { useQuery } from '@apollo/client';
import { get, isNil, map } from 'lodash/fp';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useContentTranslation } from '../../i18n';
import { getCountryId } from '../../selectors';
import { getData } from './DealerSelectionDropDown.graphql';
import BlockSelect from './form/BlockSelect';

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 15px 30px 15px 0;
`;
const Container = styled.div`
    min-width: 200px;
`;

type Dealer = {
    value: string;
    label: { [language: string]: string };
};

type DealerSelectionDropDownProps = {
    onValueChanged: (selections: string[]) => unknown;
    withWrapper?: boolean;
};

const DealerSelectionDropDown = ({ onValueChanged, withWrapper = true }: DealerSelectionDropDownProps) => {
    const { ct } = useContentTranslation();
    const countryId = useSelector(getCountryId);
    const { data, loading } = useQuery(getData, {
        fetchPolicy: 'cache-first',
        variables: { countryId },
        skip: isNil(countryId),
    });

    const dealers = data?.dealers;

    const options = useMemo(() => (dealers || []).map((option: Dealer) => ({ ...option, label: ct(option.label) })), [
        ct,
        dealers,
    ]);

    const onChange = useCallback(
        selection => {
            onValueChanged(map(get('value'), selection));
        },
        [onValueChanged]
    );

    useEffect(() => {
        if (dealers) {
            onChange(dealers);
        }
    }, [onChange, dealers]);

    if (loading) {
        return null;
    }

    const container = (
        <Container className="dealerSelectionContainer">
            <BlockSelect
                backgroundColor="#d8d8d8"
                // @ts-ignore: `defaultValue` is from react-select but somehow not present for `BlockSelect`
                defaultValue={options}
                onChange={onChange}
                options={options}
                isMulti
            />
        </Container>
    );

    return withWrapper ? <Wrapper>{container}</Wrapper> : container;
};

export default DealerSelectionDropDown;
